import { createPlugin } from '@backstage/core-plugin-api';
import {
  createTechDocsAddonExtension,
  TechDocsAddonLocations,
} from '@backstage/plugin-techdocs-react';

import { DrawioAddon } from './Drawio/Drawio';
import { DrawioProps } from './Drawio/props';

/**
 * The TechDocs addons drawio plugin
 *
 * @public
 */


export const techdocsAddonDrawioPlugin = createPlugin({
  id: 'techdocs-addon-drawio',
});

/**
 * TechDocs addon that lets you render drawio diagrams
 *
 * @public
 */

 export const Drawio = techdocsAddonDrawioPlugin.provide(
  createTechDocsAddonExtension<DrawioProps>({
    name: 'DrawioDiagram',
    location: TechDocsAddonLocations.Content,
    component: DrawioAddon,
  }),
);
