import React from 'react';
import {
  HomePageStarredEntities,
  HomePageToolkit,
  HomePageCompanyLogo,
  HeaderWorldClock,
  HomePageTopVisited,
  HomePageRecentlyVisited,
} from '@backstage/plugin-home';
import { Grid, makeStyles, useTheme } from '@material-ui/core';
import { SearchContextProvider } from '@backstage/plugin-search-react';
import { Content, GroupIcon, Header, HelpIcon, Page, UserIcon } from '@backstage/core-components';
import HomePageLogo from './HomePageLogo';
import {
  AwsDarkIcon,
  AwsIcon,
  CloudGuardIcon,
  ConfluenceIcon,
  CoverityIcon,
  DatadogDarkIcon,
  DatadogIcon,
  GitLabIcon,
  JFrogIcon,
  JiraIcon,
  MongoDbIcon,
  PagerDutyIcon,
  SendGridIcon,
  ServiceNowIcon,
  SonarQubeIcon,
  VmWareIcon,
  WorkdayIcon
} from '../../assets/customIcons';
import { AnnouncementsCard } from '@k-phoen/backstage-plugin-announcements';
import { QuestionTableCard } from '@drodil/backstage-plugin-qeta';
import { HomePageSearchBar } from '@backstage/plugin-search';
import { EscalationPolicy } from './components/on-call';

const useStyles = makeStyles(theme => ({
  searchBar: {
    display: 'flex',
    maxWidth: '60vw',
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[1],
    padding: '8px 0',
    borderRadius: '50px',
    margin: 'auto',
  },
  searchBarOutline: {
    borderStyle: 'none'
  },
}));

const useLogoStyles = makeStyles(theme => ({
  container: {
    margin: theme.spacing(1, 0),
  },
  svg: {
    width: 'auto',
    height: 100,
  },
  path: {
    fill: '#7df3e1',
  },
}));

export const HomePageContent = () => {
  const classes = useStyles();
  const { container } = useLogoStyles();
  const theme = useTheme();

  const sreOnCallServiceId = 'PDJ11LO';
  const sreOnCallIntegrationKey = '90f4ca36e6fe4c03d08b1fc1df7c208a';
  const cyberOnCallServiceId = 'PO6TMFS';
  const cyberOnCallIntegrationKey = '19060d555683470ed0e37d243736b46e';

  const sreOnCallContent = sreOnCallServiceId && sreOnCallIntegrationKey
    ? <EscalationPolicy name="SRE On-Call" serviceId={sreOnCallServiceId} integrationKey={sreOnCallIntegrationKey} />
    : null;

  const cyberOnCallContent = cyberOnCallServiceId && cyberOnCallIntegrationKey
    ? <EscalationPolicy name="Cyber On-Call" serviceId={cyberOnCallServiceId} integrationKey={cyberOnCallIntegrationKey} />
    : null;

  return (
    <SearchContextProvider>
      <Page themeId="home">
        <Header title={
          <HomePageCompanyLogo
              className={container}
              logo={<HomePageLogo />}
          />}
          pageTitleOverride="Home">
          <HeaderWorldClock key="home-page-clocks" clockConfigs={[
                    {
                      label: "Melbourne/Orlando",
                      timeZone: "America/New_York"
                    },
                    {
                      label: "Irvine",
                      timeZone: "America/Los_Angeles"
                    },
                    {
                      label: "UTC",
                      timeZone: "Etc/UTC"
                    },
                    {
                      label: "Bordeaux/Poland",
                      timeZone: "CET"
                    },
                    {
                      label: "India",
                      timeZone: "Asia/Kolkata"
                    },
                    {
                      label: "Singapore",
                      timeZone: "Asia/Singapore"
                    },
                  ]}
                />
        </Header>
        <Content>
          <Grid container justifyContent="center" spacing={6}>
            <h3 style={{ textAlign: 'center', width: '100%' }}>Backstage is designed to hold <span style={{ color: 'green' }}>THALES GROUP LIMITED DISTRIBUTION C2</span> level information</h3>
            <Grid container item xs={12} justifyContent='center'>
              <HomePageSearchBar
                autoComplete="off"
                classes={{ root: classes.searchBar }}
                InputProps={{ classes: { notchedOutline: classes.searchBarOutline } }}
                placeholder="Search"
              />
            </Grid>
            <Grid item xs={12} md={6}>
                <AnnouncementsCard key="home-page-announcements" max={3} />
            </Grid>
            <Grid container item xs={12} md={6} justifyContent='center'>
              <Grid item xs={12} md={6}>
                {sreOnCallContent}
              </Grid>
              <Grid item xs={12} md={6}>
                {cyberOnCallContent}
              </Grid>
            </Grid>
            <Grid container item xs={12}>
              <Grid item xs={12}>
                <HomePageToolkit
                  key="home-page-thales-ifec-sites"
                  title='Thales IFEC Sites'
                  tools={[
                    {
                      label: "Datadog",
                      url: "https://us3.datadoghq.com/account/login/id/661935e0-facd-11eb-8b67-da7ad0900003",
                      icon: theme.palette.type === 'dark' ? <DatadogDarkIcon /> : <DatadogIcon />
                    },
                    {
                      label: "CloudGuard",
                      url: "http://secure.dome9.com/sso/thalesifec",
                      icon: <CloudGuardIcon />
                    },
                    {
                      label: "PagerDuty",
                      url: "https://thales-ifec.pagerduty.com/incidents",
                      icon: <PagerDutyIcon />
                    },
                    {
                      label: "MongoDB Atlas",
                      url: "https://cloud.mongodb.com/v2#/org/639c89829d2a0f57aa2a39ad/projects",
                      icon: <MongoDbIcon />
                    },
                    {
                      label: "SendGrid",
                      url: "https://app.sendgrid.com/login?redirect_to=%2F",
                      icon: <SendGridIcon />
                    },
                    {
                      label: "AWS",
                      url: "https://thales-aws-sso.awsapps.com/start#/",
                      icon: theme.palette.type === 'dark' ? <AwsDarkIcon /> : <AwsIcon />
                    },
                    {
                      label: "Confluence",
                      url: "https://thales-ifec.atlassian.net/wiki/home",
                      icon: <ConfluenceIcon />
                    },
                    {
                      label: "TechOps Request Portal",
                      url: "https://thalesserviceoperations.atlassian.net/servicedesk/customer/portals",
                      icon: <JiraIcon />
                    },
                    {
                      label: "TechOps Jira",
                      url: "https://thalesserviceoperations.atlassian.net/",
                      icon: <JiraIcon />
                    },
                    {
                      label: "CloudHealth",
                      url: "https://apps.cloudhealthtech.com/login",
                      icon: <VmWareIcon fill={theme.palette.type === 'dark' ? "#FFFFFF" : "#717074"} />
                    },
                  ]}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <HomePageToolkit
                  key="home-page-tdf-sites"
                  title='Thales Digital Factory Sites'
                  tools={[
                    {
                      label: "GitLab",
                      url: "https://gitlab.thalesdigital.io/",
                      icon: <GitLabIcon />
                    },
                    {
                      label: "Artifactory",
                      url: "https://artifactory.thalesdigital.io/",
                      icon: <JFrogIcon />
                    },
                    {
                      label: "SonarQube",
                      url: "https://quality-analysis.thalesdigital.io/",
                      icon: <SonarQubeIcon />
                    },
                    {
                      label: "Coverity",
                      url: "https://coverity.thalesdigital.io/",
                      icon: <CoverityIcon />
                    },
                    {
                      label: "Jira Cloud",
                      url: "https://thales-factory.atlassian.net/",
                      icon: <JiraIcon />
                    },
                    {
                      label: "Jira Server",
                      url: "https://jira.thalesdigital.io/",
                      icon: <JiraIcon />
                    },
                    {
                      label: "TrustNest Self Service Portal",
                      url: "https://postit.service-now.com/esc/",
                      icon: <ServiceNowIcon />
                    },
                  ]}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <HomePageToolkit
                  key="home-page-thales-corp-sites"
                  title='Thales Corporate Sites'
                  tools={[
                    {
                      label: "People Online (internal)",
                      url: "https://intranet.peopleonline.corp.thales/",
                      icon: <UserIcon />
                    },
                    {
                      label: "Workday",
                      url: "https://wd3.myworkday.com/wday/authgwy/thales/login.htmld",
                      icon: <WorkdayIcon />
                    },
                    {
                      label: "Confluence (TPC - internal)",
                      url: "https://confluence.prz.ife.eu-west-1.aws.thales/",
                      icon: <ConfluenceIcon />
                    },
                    {
                      label: "Jira (TPC - internal)",
                      url: "https://jira.prz.ife.eu-west-1.aws.thales/",
                      icon: <JiraIcon />
                    },
                    {
                      label: "Team Online (internal)",
                      url: "https://ecm.corp.thales/livelink/livelink.exe?func=llworkspace",
                      icon: <GroupIcon />
                    },
                    {
                      label: "eDir Password (internal)",
                      url: "https://pam.corp.thales/PAM/",
                      icon: <HelpIcon />
                    },
                  ]}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <HomePageStarredEntities key="home-page-starred-entities" />
              </Grid>
              <Grid item xs={12} md={6}>
                <QuestionTableCard key="home-page-q-and-a" />
              </Grid>
              <Grid item xs={12} md={6}>
                <HomePageTopVisited />
              </Grid>
              <Grid item xs={12} md={6}>
                <HomePageRecentlyVisited />
              </Grid>
            </Grid>
          </Grid>
        </Content>
      </Page>
    </SearchContextProvider>
  );
};
