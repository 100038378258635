import { useEffect } from 'react';
import { useShadowRootElements } from '@backstage/plugin-techdocs-react';
import { isDrawioImgSource } from './hooks';
import { DrawioProps } from './props';

function getCookie(name: string): string | undefined {
  const value = `; ${document.cookie}`;
  const parts = value.split(`; ${name}=`);
  if (parts && parts.length === 2) {
    return parts.pop()?.split(';').shift();
  }
  return undefined;
}

/**
 * Show report issue button when text is highlighted
 */

const makeDiagram = (el: HTMLImageElement, token?: string) => {
  const width = el.style.width || '100%';
  const height = el.style.height || '500px';

  fetch(el.src, {
    headers: token ? {
      Authorization: `Bearer ${token}`
    } : {}
  }).then((v) => {
    v.text().then((t) => {
      const diagramIframe = document.createElement('iframe');
      diagramIframe.setAttribute('style', `width:${width};height:${height};`);
      diagramIframe.setAttribute('frameborder', '0');
      diagramIframe.src = `https://viewer.diagrams.net/?dark=0#R${encodeURIComponent(t)}`;
      el.parentNode?.insertBefore(diagramIframe, el.nextSibling);
    });
  });

  el.style.display = 'none';
}

export const DrawioAddon = (properties?: DrawioProps) => {
  const images = useShadowRootElements<HTMLImageElement>(['img']);

  useEffect(() => {
    if (images && images.length) {
      const token = getCookie("token");

      images.forEach(image => {
        // Skip already processed
        if (image.style.display === 'none') {
          return;
        }
        // Skip non .drawio images
        if(!isDrawioImgSource(image.src)) {
          return;
        }

        makeDiagram(image, token);
      });
    }
  }, [images, properties]);

  return null;
};
