import { createPlugin } from '@backstage/core-plugin-api';
import {
  createTechDocsAddonExtension,
  TechDocsAddonLocations,
} from '@backstage/plugin-techdocs-react';

import { ThalesDataClassificationAddon, ThalesDataClassificationProps } from './ThalesDataClassification';

/**
 * The TechDocs addons Thales data classification header plugin
 *
 * @public
 */


export const techdocsAddonThalesDataClassificationPlugin = createPlugin({
  id: 'techdocs-addon-thales-data-classification',
});

/**
 * TechDocs addon that displays the Thales data classification level
 *
 * @public
 */

 export const ThalesDataClassification = techdocsAddonThalesDataClassificationPlugin.provide(
  createTechDocsAddonExtension<ThalesDataClassificationProps>({
    name: 'ThalesDataClassificationSubHeader',
    location: TechDocsAddonLocations.Subheader,
    component: ThalesDataClassificationAddon,
  }),
);
