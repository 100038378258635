import Grid from '@material-ui/core/Grid';
import React from 'react';

export const ThalesDataClassificationAddon = () => {
  return (
    <Grid container spacing={3} alignItems='center' xs={12}>
      <Grid item xs={12}>
        <h3 style={{ textAlign: 'center', width: '100%' }}>Backstage is designed to hold <span style={{ color: 'green' }}>THALES GROUP LIMITED DISTRIBUTION C2</span> level information</h3>
      </Grid>
    </Grid>
  );
};
