import React from 'react';
import { makeStyles } from '@material-ui/core';

// const useStyles = makeStyles((theme) => {
//   const styles = {
//     svg: {
//       width: 'auto',
//       height: 50,
//       'enable-background': 'new 0 0 483.8 107.6'
//     },
//     path: {
//       fill: '#7df3e1',
//     },
//     st2: {
//       fill: '#2C2F73'
//     },
//     st4: {
//       fill: '#5EBFD4'
//     }
//   };

//   if (theme.palette.type === 'dark') {
//     styles.st2.fill = '#FFFFFF';
//   }

//   return styles;
// });

const useStyles = makeStyles(() => {
  const styles = {
    svg: {
      width: 'auto',
      height: 50,
      'enable-background': 'new 0 0 483.8 107.6'
    },
    path: {
      fill: '#7df3e1',
    },
    st2: {
      fill: '#FFFFFF'
    },
    st4: {
      fill: '#5EBFD4'
    }
  };

  return styles;
});

const HomePageLogo = () => {
  const classes = useStyles();

  return (
    <svg version="1.1"
         viewBox="0 0 483.8 107.6"
         focusable="false"
         aria-label="Thales. Building a future we can all trust. (Page d'accueil)"
         role="img"
         className={classes.svg}>
      <g>
        <g>
          <path className={classes.st2} d="M483.8,42.1c0,7.8-2.3,10.1-8.5,12c-6.7,2-18.6,3.3-25.7,3.3c-8.4,0-18.9-0.5-27.8-2.6v-9h49.3V33.5h-34.9
            c-10.5,0-14.4-2.9-14.4-13.1V15c0-8.1,2.4-10.5,8.9-12.2c6.6-1.7,17.4-2.8,24.5-2.8c8.6,0,18.9,0.7,27.8,2.7v9h-48.5V22h34.9
            c10.5,0,14.4,2.8,14.4,13.1V42.1z"/>
          <path className={classes.st2} d="M399.7,54.7c-10.2,2-20.5,2.6-30.5,2.6c-10,0-20.4-0.6-30.7-2.6V2.7c10.2-2,20.6-2.7,30.5-2.7
            c10,0,20.1,0.6,30.3,2.7v9.3h-46.2v10.4h30.1v10.8h-30.1v12.3h46.4V54.7z"/>
          <path className={classes.st2} d="M321,54.7c-9.2,2-18.4,2.6-27.3,2.6c-8.9,0-18.3-0.5-27.5-2.6V1.3h14.7v43.5H321V54.7z"/>
          <path className={classes.st2} d="M248.6,55.1c-4.9,1.4-11.4,1.9-16.2,2l-22.7-45.8h-1.3l-22.6,45.8c-4.8-0.1-10.5-0.6-15.4-2l28.7-53.7h20.5
            L248.6,55.1z"/>
          <path className={classes.st4} d="M218.7,41.4c0,5.3-4.3,9.5-9.6,9.5c-5.3,0-9.5-4.3-9.5-9.5c0-5.3,4.3-9.5,9.5-9.5
            C214.4,31.9,218.7,36.1,218.7,41.4"/>
          <path className={classes.st2} d="M152.7,55.1c-4.7,1.4-9.7,1.8-14.7,1.9V33.7h-36.5v23.3c-5-0.1-10-0.6-14.7-1.9V2.3c4.7-1.4,9.7-1.8,14.7-1.9
            v22.5h36.5V0.4c5,0.1,10,0.6,14.7,1.9V55.1z"/>
          <path className={classes.st2} d="M66.2,12.1H40.4v44H25.8v-44H0V2.7C11.1,0.7,22.3,0,33.1,0c10.8,0,22,0.6,33.1,2.7V12.1z"/>
        </g>
        <g>
          <g>
            <path className={classes.st4} d="M19.3,82.4c0,3.5-1,5.2-3.7,5.7v0.1c3.3,0.4,4.2,2.4,4.2,5.9c0,5.3-2.7,6.9-8.8,6.9c-2.7,0-7-0.1-9.9-0.3
              c-0.4,0-0.5-0.1-0.5-0.5v-24c0-0.4,0.1-0.4,0.5-0.5c2.9-0.3,6.4-0.4,9.1-0.4C16.6,75.4,19.3,77.1,19.3,82.4z M5.8,79.8v6.5h3.3
              c3.7,0,5-0.4,5-3.2c0-2.6-1.3-3.3-4.4-3.3H5.8z M5.8,90v6.5h4.9c2.8,0,4-0.7,4-3.1c0-2.7-1.3-3.4-3.9-3.4H5.8z"/>
            <path className={classes.st4} d="M38.7,82.5c0.3,0,0.5,0.2,0.5,0.5v16.8c0,0.4-0.1,0.5-0.5,0.6c-2.2,0.4-5.4,0.6-7.8,0.6
              c-6.3,0-7.9-1.2-7.9-7.9V83c0-0.3,0.2-0.5,0.5-0.5h3.9c0.3,0,0.5,0.2,0.5,0.5v8.8c0,4.7,0.5,4.9,3.5,4.9h2.9V83
              c0-0.3,0.2-0.5,0.5-0.5H38.7z"/>
            <path className={classes.st4} d="M48.3,77.5c0,1.7-1,2.6-2.8,2.6c-1.8,0-2.9-1-2.9-2.6c0-1.7,1-2.6,2.9-2.6C47.3,74.8,48.3,75.8,48.3,77.5z
              M47.4,82.5c0.3,0,0.5,0.2,0.5,0.5v17.3c0,0.4-0.2,0.5-0.5,0.5h-3.9c-0.3,0-0.5-0.2-0.5-0.5V83c0-0.3,0.2-0.5,0.5-0.5H47.4z"/>
            <path className={classes.st4} d="M56.2,75.6c0.3,0,0.5,0.2,0.5,0.5v24.3c0,0.4-0.2,0.5-0.5,0.5h-3.9c-0.3,0-0.5-0.2-0.5-0.5V76
              c0-0.3,0.2-0.5,0.5-0.5H56.2z"/>
            <path className={classes.st4} d="M75.8,75.6c0.4,0,0.5,0.2,0.5,0.5v23.5c0,0.5-0.3,0.8-0.7,0.8c-2.7,0.4-4.9,0.6-7.2,0.6
              c-6.9,0-8.5-1.9-8.5-9.3c0-7.2,1.5-9.3,7.5-9.3c1.5,0,2.8,0.1,4,0.2v-6.5c0-0.4,0.1-0.5,0.5-0.5H75.8z M68.6,86.6
              c-3.3,0-3.7,0.9-3.7,5.1c0,3.9,0.3,5.1,3.5,5.1c0.8,0,2.2-0.1,3.1-0.2v-10H68.6z"/>
            <path className={classes.st4} d="M85.5,77.5c0,1.7-1,2.6-2.8,2.6c-1.8,0-2.9-1-2.9-2.6c0-1.7,1-2.6,2.9-2.6C84.5,74.8,85.5,75.8,85.5,77.5z
              M84.5,82.5c0.3,0,0.5,0.2,0.5,0.5v17.3c0,0.4-0.2,0.5-0.5,0.5h-3.9c-0.3,0-0.5-0.2-0.5-0.5V83c0-0.3,0.2-0.5,0.5-0.5H84.5z"/>
            <path className={classes.st4} d="M105.1,90.2v10.1c0,0.4-0.2,0.5-0.5,0.5h-3.9c-0.3,0-0.5-0.2-0.5-0.5v-8.8c0-4.7-0.5-4.9-3.5-4.9h-3v13.7
              c0,0.4-0.2,0.5-0.5,0.5h-3.9c-0.3,0-0.5-0.2-0.5-0.5V83.4c0-0.4,0.2-0.4,0.5-0.5c2.2-0.5,5.4-0.6,7.8-0.6
              C103.5,82.4,105.1,83.6,105.1,90.2z"/>
            <path className={classes.st4} d="M124,83c0.4,0.1,0.7,0.4,0.7,0.8v16.6c0,4.9-1,7.1-8,7.1c-2,0-4.1-0.1-6-0.5c-0.3,0-0.5-0.2-0.5-0.5v-2.5
              c0-0.4,0.1-0.5,0.5-0.5h5.6c2.9,0,3.6-0.7,3.6-2.8v-0.2c-1.4,0.3-2.6,0.4-4,0.4c-5.9,0-7.5-2.1-7.5-9.6c0-7.1,1.6-9,8.5-9
              C119.4,82.4,122.1,82.6,124,83z M116.6,86.6c-3.2,0-3.5,1.2-3.5,5.1c0,4.2,0.5,5.1,3.8,5.1c0.8,0,2,0,2.9-0.2v-10H116.6z"/>
            <path className={classes.st4} d="M150,88v11.3c0,0.5-0.1,0.8-0.7,1c-2.3,0.5-5.2,0.7-7.4,0.7c-4.9,0-7.5-0.5-7.5-5.6c0.1-4.4,2.4-5.4,6.7-5.4
              h4.1v-1.2c0-1.6-1-2.3-3.1-2.3h-5.9c-0.4,0-0.5-0.1-0.5-0.5v-2.7c0-0.4,0.1-0.5,0.5-0.5c2-0.4,3.9-0.5,5.9-0.5
              C148.6,82.4,150,84.5,150,88z M141.4,93.1c-1.7,0-2.3,0.6-2.3,2c0,1.6,0.7,2,2.8,2c0.7,0,2.3-0.1,3.3-0.3v-3.6H141.4z"/>
            <path className={classes.st4} d="M171.1,75.7c0.4,0.1,0.5,0.2,0.5,0.6v2.7c0,0.4-0.2,0.5-0.6,0.5h-2.6c-1.3,0-1.8,0.2-1.8,1.3v1.7h4
              c0.4,0,0.5,0.2,0.4,0.6l-0.5,2.7c-0.1,0.3-0.3,0.5-0.6,0.5h-3.3v14c0,0.4-0.2,0.5-0.5,0.5h-3.9c-0.3,0-0.5-0.2-0.5-0.5v-14h-2.1
              c-0.4,0-0.6-0.2-0.6-0.5v-2.8c0-0.3,0.2-0.5,0.6-0.5h2.1V80c0-4.1,2.8-4.6,5.7-4.6C168.6,75.4,169.9,75.5,171.1,75.7z"/>
            <path className={classes.st4} d="M188.9,82.5c0.3,0,0.5,0.2,0.5,0.5v16.8c0,0.4-0.1,0.5-0.5,0.6c-2.2,0.4-5.4,0.6-7.8,0.6
              c-6.3,0-7.9-1.2-7.9-7.9V83c0-0.3,0.2-0.5,0.5-0.5h3.9c0.3,0,0.5,0.2,0.5,0.5v8.8c0,4.7,0.5,4.9,3.5,4.9h2.9V83
              c0-0.3,0.2-0.5,0.5-0.5H188.9z"/>
            <path className={classes.st4} d="M198.7,77.5c0.3-0.1,0.5,0.1,0.5,0.5v4.7h3c0.4,0,0.6,0.2,0.6,0.5v2.6c0,0.3-0.2,0.5-0.6,0.5h-3v8.9
              c0,1.4,0.5,1.7,1.9,1.7h1.3c0.4,0,0.6,0.1,0.6,0.5v2.8c0,0.4-0.2,0.6-0.5,0.6c-1.1,0.2-2.1,0.3-2.8,0.3c-2.8,0-5.4-0.1-5.4-4.2
              V86.2h-1.9c-0.4,0-0.5-0.2-0.5-0.5v-2.6c0-0.3,0.2-0.5,0.5-0.5h1.9V79c0-0.3,0.1-0.5,0.5-0.5L198.7,77.5z"/>
            <path className={classes.st4} d="M221.2,82.5c0.3,0,0.5,0.2,0.5,0.5v16.8c0,0.4-0.1,0.5-0.5,0.6c-2.2,0.4-5.4,0.6-7.8,0.6
              c-6.3,0-7.9-1.2-7.9-7.9V83c0-0.3,0.2-0.5,0.5-0.5h3.9c0.3,0,0.5,0.2,0.5,0.5v8.8c0,4.7,0.5,4.9,3.5,4.9h2.9V83
              c0-0.3,0.2-0.5,0.5-0.5H221.2z"/>
            <path className={classes.st4} d="M235.8,82.7c0.4,0.1,0.5,0.2,0.5,0.7v2.7c0,0.3-0.2,0.5-0.6,0.5h-3.1c-1.7,0-2.3,0.3-2.3,1.7v12.1
              c0,0.4-0.2,0.5-0.5,0.5H226c-0.3,0-0.5-0.2-0.5-0.5V87c0-4.1,2.9-4.6,5.9-4.6C232.4,82.4,234.6,82.5,235.8,82.7z"/>
            <path className={classes.st4} d="M254.3,91.5v1c0,0.7-0.2,0.9-1,0.9h-10.2c0.1,2.9,0.8,3.5,3.7,3.5h5.8c0.3,0,0.5,0.2,0.5,0.5v2.7
              c0,0.4-0.2,0.5-0.5,0.5c-1.7,0.4-4.1,0.5-6.2,0.5c-6.6,0-8.2-1.9-8.2-9.3c0-7.5,1.6-9.3,8.2-9.3
              C252.7,82.4,254.3,84.2,254.3,91.5z M243,90.2h6.5c0-2.9-0.8-3.6-3.2-3.6C243.9,86.6,243.1,87.3,243,90.2z"/>
            <path className={classes.st2} d="M288,83c0.4,0,0.5,0.1,0.4,0.5l-2.9,13.8c-0.7,3.2-1.2,3.7-3.8,3.7c-2.9,0-3-0.4-3.8-3.7l-2.1-9.1l-2.1,9.1
              c-0.8,3.3-0.9,3.7-3.8,3.7c-2.6,0-3.1-0.5-3.8-3.7l-2.9-13.8c-0.1-0.4,0-0.5,0.4-0.5h2.4c0.2,0,0.3,0.1,0.4,0.5l2.7,13.4
              c0.3,1.1,0.5,1.2,0.9,1.2c0.6,0,0.6-0.1,0.9-1.2L274,84c0.1-0.4,0.2-0.5,0.5-0.5h2.7c0.3,0,0.4,0.1,0.5,0.5l3.2,12.9
              c0.3,1.1,0.3,1.2,0.9,1.2c0.4,0,0.6-0.1,0.9-1.2l2.7-13.4c0.1-0.4,0.2-0.5,0.4-0.5H288z"/>
            <path className={classes.st2} d="M305.9,91.4v0.9c0,0.7-0.2,0.9-1.2,0.9h-10.4c0.1,4,0.8,5,4.3,5h5.4c0.3,0,0.5,0.2,0.5,0.5v1.4
              c0,0.3-0.2,0.5-0.5,0.5c-1.7,0.2-3.5,0.3-5.5,0.3c-6.1,0-7.4-1.8-7.4-9.1s1.3-9.1,7.4-9.1C304.4,82.8,305.8,84.5,305.9,91.4z
              M294.3,90.7h8.5c-0.1-4.1-0.8-5.2-4.1-5.2C295.1,85.5,294.4,86.6,294.3,90.7z"/>
            <path className={classes.st2} d="M328.9,83.1c0.3,0,0.5,0.2,0.5,0.6v1.4c0,0.3-0.2,0.5-0.5,0.5h-4.9c-3.9,0-4.4,1.3-4.4,6.4
              c0,5.1,0.5,6.3,4.4,6.3h4.9c0.3,0,0.5,0.2,0.5,0.5v1.4c0,0.3-0.2,0.5-0.5,0.6c-1.7,0.2-3.6,0.3-5,0.3c-6.3,0-7.6-1.8-7.6-9.1
              s1.2-9.1,7.6-9.1C325.3,82.8,327.2,82.9,328.9,83.1z"/>
            <path className={classes.st2} d="M345.8,87.9v11.4c0,0.3,0,0.7-0.6,0.9c-1.1,0.4-4,0.7-7.2,0.7c-4.3,0-6.3-0.9-6.3-5.3c0-3.9,2.1-5,5.8-5
              l5.3,0v-2.2c0-2-1.2-2.9-4.2-2.9h-4.9c-0.4,0-0.5-0.1-0.5-0.5v-1.3c0-0.4,0.1-0.5,0.5-0.5c1.9-0.3,3.6-0.4,4.9-0.4
              C343.5,82.8,345.8,84.4,345.8,87.9z M337.4,93.2c-1.8,0-2.7,0.6-2.7,2.4c0,2.2,1.1,2.6,3.8,2.6c1,0,2.9-0.1,4.2-0.4v-4.7H337.4z"
              />
            <path className={classes.st2} d="M365,90.4v9.9c0,0.3-0.2,0.5-0.6,0.5h-2.1c-0.3,0-0.5-0.2-0.5-0.5v-9.6c0-5-1-5.2-4.1-5.2h-4.5v14.8
              c0,0.3-0.2,0.5-0.5,0.5h-2.2c-0.3,0-0.5-0.2-0.5-0.5V83.9c0-0.4,0.2-0.5,0.5-0.5c2.5-0.5,5.2-0.5,6.8-0.5
              C363.2,82.8,365,83.5,365,90.4z"/>
            <path className={classes.st2} d="M389.8,87.9v11.4c0,0.3,0,0.7-0.6,0.9c-1.1,0.4-4,0.7-7.2,0.7c-4.3,0-6.3-0.9-6.3-5.3c0-3.9,2.1-5,5.8-5
              l5.3,0v-2.2c0-2-1.2-2.9-4.2-2.9h-4.9c-0.4,0-0.5-0.1-0.5-0.5v-1.3c0-0.4,0.1-0.5,0.5-0.5c1.9-0.3,3.6-0.4,4.9-0.4
              C387.5,82.8,389.8,84.4,389.8,87.9z M381.4,93.2c-1.8,0-2.7,0.6-2.7,2.4c0,2.2,1.1,2.6,3.8,2.6c1,0,2.9-0.1,4.2-0.4v-4.7H381.4z"
              />
            <path className={classes.st2} d="M396.8,76.1c0.3,0,0.5,0.2,0.5,0.5v23.7c0,0.3-0.2,0.5-0.5,0.5h-2.2c-0.3,0-0.5-0.2-0.5-0.5V76.6
              c0-0.3,0.2-0.5,0.5-0.5H396.8z"/>
            <path className={classes.st2} d="M404.4,76.1c0.3,0,0.5,0.2,0.5,0.5v23.7c0,0.3-0.2,0.5-0.5,0.5h-2.2c-0.3,0-0.5-0.2-0.5-0.5V76.6
              c0-0.3,0.2-0.5,0.5-0.5H404.4z"/>
            <path className={classes.st2} d="M420.3,78c0.4-0.1,0.6,0.1,0.6,0.4V83h3.4c0.4,0,0.5,0.2,0.5,0.5V85c0,0.3-0.2,0.5-0.5,0.5h-3.4v11
              c0,1.4,0.4,1.8,2.1,1.8h1.6c0.4,0,0.5,0.1,0.5,0.5v1.6c0,0.3-0.2,0.5-0.5,0.5c-0.7,0.2-1.5,0.2-1.8,0.2c-2.3,0-5,0-5-3.7V85.5
              h-1.8c-0.3,0-0.5-0.2-0.5-0.5v-1.5c0-0.3,0.2-0.5,0.5-0.5h1.8v-3.9c0-0.3,0.1-0.5,0.4-0.5L420.3,78z"/>
            <path className={classes.st2} d="M436.7,83.2c0.3,0.1,0.5,0.2,0.5,0.6v1.4c0,0.3-0.2,0.5-0.5,0.5h-2.8c-2.3,0-2.8,0.4-2.8,1.8v13
              c0,0.3-0.2,0.5-0.5,0.5h-2.1c-0.3,0-0.5-0.2-0.5-0.5V86.6c0-3.7,3.3-3.8,5.2-3.8C433.8,82.8,436.1,82.9,436.7,83.2z"/>
            <path className={classes.st2} d="M454.1,83c0.3,0,0.5,0.2,0.5,0.5V100c0,0.4-0.2,0.4-0.5,0.5c-2.6,0.5-5.3,0.5-6.8,0.5
              c-5.8,0-7.6-0.8-7.6-7.7v-9.9c0-0.3,0.2-0.5,0.6-0.5h2.1c0.3,0,0.5,0.2,0.5,0.5v9.6c0,5,1,5.2,4.1,5.2h4.5V83.5
              c0-0.3,0.2-0.5,0.5-0.5H454.1z"/>
            <path className={classes.st2} d="M470.2,83.1c0.3,0,0.5,0.2,0.5,0.5V85c0,0.3-0.2,0.5-0.5,0.5h-6.1c-1.5,0-2.4,0.5-2.4,1.7v0.4
              c0,0.9,0.4,1.7,2,2.3l4.3,1.7c3,1.1,3.3,2.8,3.3,4.6v0.4c0,3.7-2.2,4.4-6.6,4.4c-2.8,0-5.4-0.2-6-0.3c-0.4,0-0.5-0.2-0.5-0.5
              v-1.4c0-0.3,0.2-0.5,0.5-0.5h6c2.6,0,3.4-0.3,3.4-1.7v-0.4c0-0.9-0.4-1.6-1.9-2.2l-4.6-1.8c-2.5-1-3.1-2.9-3.1-4.7v-0.7
              c0-3.6,3.1-4.1,6.6-4.1C466.6,82.8,468.3,82.9,470.2,83.1z"/>
            <path className={classes.st2} d="M478.4,78c0.4-0.1,0.6,0.1,0.6,0.4V83h3.4c0.4,0,0.5,0.2,0.5,0.5V85c0,0.3-0.2,0.5-0.5,0.5h-3.4v11
              c0,1.4,0.4,1.8,2.1,1.8h1.6c0.4,0,0.5,0.1,0.5,0.5v1.6c0,0.3-0.2,0.5-0.5,0.5c-0.7,0.2-1.5,0.2-1.8,0.2c-2.3,0-5,0-5-3.7V85.5
              H474c-0.3,0-0.5-0.2-0.5-0.5v-1.5c0-0.3,0.2-0.5,0.5-0.5h1.8v-3.9c0-0.3,0.1-0.5,0.4-0.5L478.4,78z"/>
          </g>
        </g>
      </g>
    </svg>
  );
};

export default HomePageLogo;
